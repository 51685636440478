.row:before, .row:after {
	display: none !important;
}

.row.reorder-mobile{
	@include media-breakpoint-up(lg){
		flex-direction: row-reverse;
	}
}

body{
	background-color: $white;
}

.content-wrapper{
	margin: 50px 0;
}

.scroll-cta{
	display: none;
	font-size: 22px;
	font-weight: 700;
    position: fixed;
    top: 50%;
	z-index: 100;
	transform: translateX(-12px);
	transition: $trans1;

	&.scrolled-down{
		transform: translateX(-80px);
	}

	&:hover{
		transform: translateX(-7px);
	}

	a{
		position: relative;
		top: -5px;
		text-decoration: none;

		img{
			width: 100px;
		}
	}

	@media screen and (max-width: 1600px) {

		a{
			img{
				width: 80px;
			}
		}

		&.scrolled-down{
			transform: translateX(-65px);
		}

		&:hover{
			transform: translateX(-9px);
		}

	}

	@include media-breakpoint-up(lg){
		display: inherit;
	}
}

/* Button */

.btn{
	display: inline-block;
	padding: 12px 25px;
	background-color: $orange;
	text-transform: uppercase;
	color: $white;
	font-weight: 600;
	text-decoration: none;
	transition: $trans1;
	margin-top: 35px !important;

	@include media-breakpoint-down(lg) {
		margin-top: 15px !important;
		padding: 10px 20px;
	}

	&:hover{
		background-color: $purple;
		color: $white !important;
	}
}

/* Content blocks */

// Backgrounds

.bg{
	&-purple{
		background-color: $purple;

		.btn{
			color: $purple !important;
			background-color: $white !important;
		}
	}

	&-orange{
		background-color: $orange;

		.btn{
			color: $orange !important;
			background-color: $white !important;
		}
	}
}

// Borders

.border{

	&-purple{
		border-color: $purple;
	}

	&-orange{
		border-color: $orange;
	}

	&-pink{
		border-color: $pink;
	}

	&-red{
		border-color: $red;
	}

	&-yellow{
		border-color: $yellow;
	}

	&-green{
		border-color: $green;
	}

	&-white{
		border-color: $white;
	}
}

// Modules

.cb-mod{
	margin-bottom: 75px;

	@include media-breakpoint-down(lg) {
		margin-bottom: 50px;
	}

	.inner{

		&-text-left{
			padding: 15px 25px 0 0;

			@include media-breakpoint-down(lg) {
				padding: 25px 0;
			}
		}

		&-text-right{
			padding: 15px 0 0 25px;

			@include media-breakpoint-down(lg) {
				padding: 25px 0 0 0;
			}
		}
	}

	&-tekst{
		
	}

	/* Tekst + image */

	&-tekst-img{
		img{
			max-width: 100%;
			border-style: solid;
			border-width: 10px;
		}
	}

	&-tekst-img.bg-orange,
	&-tekst-img.bg-purple{
		padding: 60px 0;

		@include media-breakpoint-down(lg) {
			padding: 20px 0 60px 0;
		}

		p, ul, ol{
			color: $white;
		}

		h2{
			color: $white;
		}
	}

	&-tekst-vid{
		margin-bottom: 135px;

		iframe{
			width: 100%;

			@include media-breakpoint-down(lg) {
				margin-bottom: 50px;
			}

			@include media-breakpoint-up(xl) {
				height: 110%;
			}
		}
	}

	&-tekst-tabel{

	}

	&-banner{
		.banner-inner{
			position: relative;
			padding: 50px 0;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: 50% 50%;

			&::before{
				content: '';
				background-color: $black;
				width: 100%;
				height: 100%;
				opacity: 0.5;
				position: absolute;
				left: 0;
				top: 0;
				z-index: 2;
			}

			.banner-content{
				position: relative;
				z-index: 5;

				h2{
					color: $white;
				}

				p{
					color: $white;
				}

				@include media-breakpoint-down(lg) {
					padding: 0 25px;
				}
			}
		}
	}

	/* Quote */

	&-quote{
		padding: 60px 0;
		position: relative;
		margin-top: 100px;

		@include media-breakpoint-down(lg) {
			margin-top: 50px;
			padding: 40px 0;
		}

		&::before{
			content:'';
			position: absolute;
			width: 100%;
			height: 70px;
			clip-path: polygon(100% 0, 0% 100%, 100% 100%);
			top: -69px;
			left: 0;

			@include media-breakpoint-down(lg) {
				height: 40px;
				top: -39px;
			}
		}

		span{
			font-size: 36px;
			line-height: 50px;
			display: block;
			margin-bottom: 25px;
			font-weight: 700;
			color: $white;

			@include media-breakpoint-down(lg) {
				font-size: 26px;
				line-height: 38px;
			}
		}
	}

	&-quote.bg-purple{

		&::before{
			background-color: $purple;
		}

	}

	&-quote.bg-orange{

		&::before{
			background-color: $orange;
		}

	}

	&-quote.bg-transparent{

		margin-top: 50px !important;

		span{
			color: $purple;
		}

	}

	/* Vergelijking */

	&-vergelijking{
		.vergelijking-col{

			.vergelijking-inner{
				padding: 50px;
				height: 100%;

				min-height: 648px;

				@include media-breakpoint-down(lg) {
					min-height: auto;
				}

				.number{
					font-size: 36px;
					color: $white;
					font-weight: bold;
					display: block;
					text-align: center;
					margin-bottom: 30px;
					position: relative;

					&::after{
						content: '';
						width: 50px;
						height: 3px;
						background-color: $white;
						position: absolute;
						bottom: -5px;
						left: 50%;
						transform: translateX(-50%);
					}

					@include media-breakpoint-down(sm) {
						font-size: 32px;
					}
				}

				h2{
					color: $white !important;
					text-align: center;
					padding: 0 25px;
					margin-bottom: 50px;

					@include media-breakpoint-down(lg) {
						margin-bottom: 25px;
					}
				}

				p, ul, ol{
					color: $white !important;
				}

			}

			&:first-of-type{
				@include media-breakpoint-up(lg) {
					padding-right: 0;
				}

				.vergelijking-inner{
					background-color: $orange;
				}
			}

			&:last-of-type{
				@include media-breakpoint-up(lg) {
					padding-left: 0;
				}

				.vergelijking-inner{
					background-color: $purple;
				}
			}

		}
	}
}