footer{
	background-color: #FEF4E8;
	margin-top: 200px;
	position: relative;

	@include media-breakpoint-down(lg) {
		padding-bottom: 50px;
	}

	@include media-breakpoint-down(sm) {
		margin-top: 130px;
	}

	&::before{
		content: "";
		position: absolute;
		width: 100%;
		height: 70px;
		clip-path: polygon(0% 0, 0% 100%, 100% 100%);
		top: -69px;
		left: 0;
		background-color: #FEF4E8;

		@include media-breakpoint-down(sm) {
			height: 40px;
			top: -39px;
		}
	}

	.footer-col{
		.footer-logo{
			max-width: 160px;
			mix-blend-mode: darken;
			margin-bottom: 25px;
			padding-top: 50px;
			
			@include media-breakpoint-down(sm) {
				max-width: 120px;
				padding-top: 25px;
			}
		}

		ul{
			list-style: none;
			padding: 0;
			padding-bottom: 50px;

			@include media-breakpoint-down(lg) {
				padding-bottom: 25px;
			}

			li{
				a{
					font-weight: 400;
					color: $grey-dark;
					transition: $trans1;

					&:hover{
						color: $purple;
					}
				}
			}
		}

		&:nth-of-type(2){
			.footer-logo{
				max-width: 165px;

				@include media-breakpoint-down(sm) {
					max-width: 125px;
					margin-bottom: 21px;
				}
			}
		}

		&:nth-of-type(3){
			padding-left: 30px;
			padding-right: 30px;
			position: relative;

			@include media-breakpoint-down(xxl) {
				padding-left: 15px;
				padding-right: 15px;
			}

			@include media-breakpoint-down(sm) {
				padding: 0;
			}

			&::before{
				content: "";
				position: absolute;
				width: 100%;
				height: 70px;
				clip-path: polygon(0% 37px, 0% 100%, 100% 100%, 100% 67%);
				top: -69px;
				left: 30px;
				background-color: $orange;
				width: calc(100% - 60px);

				@include media-breakpoint-down(xxl) {
					width: calc(100% - 30px);
					clip-path: polygon(0% 37px, 0% 100%, 100% 100%, 100% 68%);
					left: 15px;
				}

				@include media-breakpoint-down(lg) {
					display: none;
				}
			}

			.invest-cta{
				background-color: $orange;
				display: block;
				height: 100%;
				padding: 25px;
				text-align: center;

				@include media-breakpoint-down(lg) {
					width: calc(100% - 30px);
				}

				@include media-breakpoint-down(md) {
					width: 100%;
				}

				img{
					display: block;
					margin: 0 auto;
					margin-bottom: 25px;
					margin-top: 50px;

					@include media-breakpoint-down(lg) {
						margin-top: 10px;
					}
				}

				a{
					text-transform: uppercase;
					font-weight: bold;
					color: $white !important;
					text-decoration: none;
					font-size: 26px;
					line-height: 38px;

					@include media-breakpoint-down(lg) {
						font-size: 22px;
						line-height: 28px;
					}

				}
			}
		}

		&:nth-of-type(4){
			.last-col-inner{
				padding-left: 25px;
				padding-top: 75px;

				@include media-breakpoint-down(lg) {
					padding-top: 5px;
					padding-left: 0;
				}

				@include media-breakpoint-down(md) {
					padding-left: 0px;
					padding-right: 0px;
					padding-top: 37px;
				}

				a{
					font-size: 19px;
					font-weight: bold;
					text-transform: uppercase;
					text-decoration: none;
					color: $grey-dark;
				}

				.form-wrap{
					margin-top: 30px;

					span{
						font-size: 19px;
						color: $grey-dark;
						font-weight: bold;
						text-transform: uppercase;
					}

					input, button{
						width: 100%;
						text-align: center;
						font-size: 17px;
						border: none;
						padding: 10px 5px;

						@include media-breakpoint-down(lg) {
							font-size: 16px;
						}
					}

					button{
						color: $grey-dark;
						text-transform: uppercase;
						background-color: $orange;
						transition: $trans1;
						font-weight: bold;

						&:hover{
							background-color: rgba($orange, 0.5);
						}
					}

				}
			}
		}
	}
}

.footer-final{
	padding: 25px 0;

	a{
		color: $grey-dark;
		text-decoration: none;
	}

	.socials{

		@include media-breakpoint-down(sm) {
			margin-bottom: 25px;
		}

		a{
			margin-right: 25px;

			&:last-of-type{
				margin-right: 0;
			}
		}
	}

	.disclaimer-row{
		@include media-breakpoint-down(sm) {
			margin-bottom: 10px;
		}
	}
}