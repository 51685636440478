.dataTables_wrapper{
	tr th, tr td{
		text-align: center !important;
		padding: 13px 10px !important;
	}

	tr{

		th{
			background-color: $purple !important;
			color: $white !important;
			border: 1px solid $purple;
		}

		td{
			border: 1px solid #ddd;

			&:nth-of-type(2n-1){
				background-color: rgba($purple, 0.15) !important;
			}

			&:nth-of-type(2n){
				background-color: $white !important;
			}
		}

	}
}