/**
 * Global variables
 **/

$image_path: 'images/';

/**
 * Colors
 **/

$orange: #f6921e;
$purple: #9e1f63;
$pink: #ec297b;
$green: #7aa930;
$yellow: #f9d529;
$red: #ee4036;
$white: #FFFFFF;
$black: #000000;
$grey-dark: rgb(60, 60, 60);

$trans1: all 0.1s ease-in;