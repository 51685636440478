/**
 * Mobile only
 **/

@include media-breakpoint-down(lg) {
	#menu-entries{
		display: none !important;
	
		&.show{
			display: block !important;
			width: 100% !important;
		}

		ul{
			width: 100%;
		}
	}

	.navbar{
		display: block !important;
	}

	.navbar-toggler{
		float: right;
	}
}

#menu-entries{
	.dropdown.child{
		position: relative !important;
		.dropdown-sub{
			position: absolute !important;
			right: 0;
		}
	}
}



/* CHP Import */

header{position: relative; text-align: center; padding-top: 15px;}
header .logo{display: inline-block; margin: 0 auto !important; margin-bottom: 20px !important;}
header .logo img{width: 180px !important; margin: 0 auto; margin-top: 0px !important;}
header .top-nav{position: absolute; top: 50px; right: 75px; text-align: center; color: #1b75bb; font-weight: bold;}
header .top-nav a{text-transform: lowercase; color: $grey-dark !important; font-weight: 400 !important; text-decoration: none; transition: $trans1;}
header .top-nav a::after{content: '|'; margin-left: 15px; margin-right: 15px;}
header .top-nav a:last-of-type::after{display: none;}
header .top-nav a:hover, .current-menu-ancestor > a, .current-menu-item > a{color: $orange !important;}
header .top-nav img{width: 25px; margin: 0 auto;}

nav{width: 100% !important; z-index: 25 !important; margin-bottom: 25px;}
nav ul{text-align: center; padding-left: 0 !important; position: relative; left: 50%; transform: translateX(-50%);}
nav ul li{display: inline;}
nav ul li:first-child a{padding-left: 0 !important;}
nav ul li:last-child a{padding-right: 0 !important;}
nav ul li a{font-size: 18.275px !important; color: rgb(60,60,60) !important; text-transform: lowercase; text-decoration: none !important; padding: 0px 50px !important;}
header nav ul li a{font-weight: 600 !important;}
nav ul li a:hover{color: $orange !important;}

header .dropdown-menu{border: none; position: absolute; z-index: 100 !important; box-shadow: none !important; padding-top: 0 !important; padding-bottom: 0 !important; left: 95px; min-width: 250px; max-width: 100%; text-align: center;}
header .dropdown-menu a, .dropdown-menu a:first-child, .dropdown-menu a:last-child{font-size: 14px !important; padding: 10px 25px !important; padding-left: 25px !important; padding-right: 25px !important;}
header .dropdown-menu a:hover{background-color: $purple !important; color: white !important;}



@include media-breakpoint-down(lg) {
	header{padding-top: 50px;}
	header .top-nav{right: 25px; top: 15px;}

	.navbar{
		margin-bottom: 0;
		padding: 0;

		#menu-entries{
			border-bottom: 2px solid $orange;

			ul{
				position: relative;
				bottom: 20px;
				li{
					a{
						padding: 5px 0 !important;
					}

					.dropdown-menu{
						bottom: 0;
						left: 50%;
						min-width: none;
						background-color: $purple;
						border-radius: 0 !important;
						padding: 10px 0 !important;

						a{
							color: $white !important;
						}
					}
				}
			}
		}
	}
}

@include media-breakpoint-down(sm) {
	header .logo img{width: 140px !important; margin: 20px auto;}
}



/* Invest */

.header-image{
	height: 400px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50% 50%;
	position: relative;

	@include media-breakpoint-down(lg) {
		height: 300px;
	}

	.header-cta{
		width: 150px;
		position: absolute;
		top: -75px;
		z-index: 11;
		right: 250px;
		z-index: 10;

		@include media-breakpoint-down(sm) {
			display: none;
		}
	}

	.header-inner{
		position: relative;
		width: 100%;
		height: 100%;
		z-index: 1;

		&::after{
			content: '';
			background-color: $black;
			width: 100%;
			height: 100%;
			opacity: 0.5;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 2;
		}

		.header-title{
			position: absolute;
			z-index: 5;
			text-align: left;
			top: 50%;
			transform: translateY(-50%);
			max-width: 770px;

			@include media-breakpoint-down(lg) {
				transform: none;
				top: auto;
				bottom: 25px;
			}

			span{
				color: $white;
				font-size: 54px;
				line-height: 74px;
				display: block;
				margin-bottom: 50px;

				@include media-breakpoint-down(lg) {
					font-size: 28px;
					line-height: 40px;
					margin-bottom: 0;
				}
			}

			.btn{
				margin-top: 0 !important;

				@include media-breakpoint-down(lg) {
					margin-top: 25px !important;
				}
			}

		}

	}
}

.page-template-template-front{
	.header-image{
		height: 600px;

		@include media-breakpoint-down(xl) {
			height: 450px;
		}

		@include media-breakpoint-down(lg) {
			height: 300px;
		}

		&::before, &::after{
			content: '';
			position: absolute;
			border-style: solid;
	    	
			z-index: 10;
		}

		@include media-breakpoint-up(xl) {

			&::before{
				border-color: transparent transparent transparent $white;
				border-width: 0 0 80px 350px;
				top: 0;
				left: 0;
			}

			&::after{
				border-color: transparent $white transparent transparent;
				border-width: 80px 350px 0 0;
				bottom: 0;
				right: 0;
			}

		}
	}
}

.navbar-toggler{
	background-color: $orange;
	border: none;
	color: $white;
	border-radius: 0;
	bottom: 55px;
	position: relative;
	right: 25px;

	span{
		background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255,1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
	}
}

@media only screen and (max-width: 1750px){

	.header-image .header-cta{
		right: 135px;

		img{
			width: 150px;
		}
	}

}

@media only screen and (max-width: 1499px){

	.header-image .header-cta{
		right: 60px;

		img{
			width: 105px;
		}
	}

}

@media only screen and (max-width: 1199px){

	.header-image .header-cta{
		right: 60px;
		top: -27px;

		img{
			width: 95px;
		}
	}

}

@media only screen and (max-width: 767px){

	.header-image .header-cta{
		right: -12px;
		top: -15px;

		img{
			width: 95px;
		}
	}

}