h1{
	font-size: 42px;
	text-align: center;
	margin: 0 0 50px 0 !important;

	strong, b{
		color: $orange;
	}
}

p, ul, ol{
	font-size: 17px;
	color: $grey-dark;
	line-height: 31px;
	margin-bottom: 17px;

	@include media-breakpoint-down(lg) {
		font-size: 16px;
		line-height: 30px;
	}

	a{
		color: $orange;
		font-weight: bold;
		text-decoration: none;
		transition: $trans1;

		&:hover{
			color: $purple;
		}
	}
}

h2{
	color: $purple;
	font-weight: 700;
	font-size: 36px;
	line-height: 48px;
	margin-bottom: 15px;

	@include media-breakpoint-down(lg) {
		font-size: 24px;
		line-height: 36px;
	}
}